import {notEmptyRegexpValidator, emptyRegexpValidator} from '../utils';

export const NUMERIC_VALIDATOR_KEY = 'numeric';

export const WHOLE_NUMERIC_VALIDATOR_KEY = 'whole-numeric';

export const NUMERIC_NOT_EMPTY_VALIDATOR_KEY = 'numeric_not_empty';

export const numericValidator = notEmptyRegexpValidator(/^[0-9]*$/i, NUMERIC_VALIDATOR_KEY);

export const wholeNumericValidator = notEmptyRegexpValidator(/^[0-9]*$/i, WHOLE_NUMERIC_VALIDATOR_KEY);

export const numericEmptyValidator = emptyRegexpValidator(/^[0-9]*$/i, NUMERIC_VALIDATOR_KEY);

export const numericNotEmptyValidator = notEmptyRegexpValidator(/^[0-9]*$/i, NUMERIC_NOT_EMPTY_VALIDATOR_KEY);
